import React, { FC } from 'react';

const FallbackAvatar: FC<{}> = () => (
  <svg
    data-testid="fallback-avatar"
    className="fallback-avatar"
    width="44px"
    height="44px"
    viewBox="0 0 44 44"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/Thumbnail/56x56</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-4">
        <circle id="Oval" fill="#D8D8D8" cx="22" cy="22" r="22"></circle>
        <g id="Group" transform="translate(11.785714, 11.785714)" fill="#BABABA" fillRule="nonzero">
          <g id="Group-6">
            <path
              d="M5.79756023e-14,4 L5.79756023e-14,17.1578647 C5.82461438e-14,19.3670037 1.790861,21.1578647 4,21.1578647 L17.1578647,21.1578647 C19.3670037,21.1578647 21.1578647,19.3670037 21.1578647,17.1578647 L21.1578647,4 C21.1578647,1.790861 19.3670037,-3.38267399e-15 17.1578647,-3.86504016e-15 L4,-3.86504016e-15 C1.790861,-3.45922791e-15 5.85932393e-14,1.790861 5.79756023e-14,4 Z M19.0420783,2.11578647 L19.0420783,13.3153495 L15.8430091,10.1162804 L12.6665084,13.2927812 L7.40243161,8.02870441 L2.11578647,13.3153495 L2.11578647,2.11578647 L19.0420783,2.11578647 Z"
              id="Shape"
            ></path>
            <circle id="Oval" cx="12.1643617" cy="5.81700228" r="1.58542933"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default FallbackAvatar;
