import { RadioClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { iconActionStyle } from './checkboxTheme';

export const radioTheme: Partial<Record<RadioClassKey, CSSProperties>> = {
  root: {
    '&.MuiIconButton-root': iconActionStyle,
  },
};

export default { radioTheme };
