/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-extraneous-dependencies
import AWS from "aws-sdk";
import * as correlationIds from "./correlation-ids";

const LogLevels = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
};

const DEFAULT_CONTEXT = {
  sdkVersion: (AWS as any).VERSION,
  awsRegion: process.env.AWS_REGION || process.env.AWS_DEFAULT_REGION,
  functionName: process.env.AWS_LAMBDA_FUNCTION_NAME,
  functionVersion: process.env.AWS_LAMBDA_FUNCTION_VERSION,
  functionMemorySize: process.env.AWS_LAMBDA_FUNCTION_MEMORY_SIZE,
  stage: process.env.STAGE,
};

const getContext = (): Record<string, any> => {
  const context = correlationIds.get();
  if (context) {
    return { ...DEFAULT_CONTEXT, ...context };
  }
  return DEFAULT_CONTEXT;
};

type LogLevelStrings = "DEBUG" | "INFO" | "WARN" | "ERROR";
// default to debug if not specified
const logLevelName = process.env.log_level || "DEBUG";

const isEnabled = (level): boolean => {
  return level >= LogLevels[logLevelName];
};

const appendError = (
  params?: Record<string, any>,
  err?: Error
): Record<string, any> | undefined => {
  if (!err) {
    return params;
  }

  return {
    ...(params || {}),
    errorName: err.name,
    errorMessage: err.message,
    stackTrace: err.stack,
  };
};

const log = (
  levelName: LogLevelStrings,
  message: string,
  params?: Record<string, any>
): void => {
  if (!isEnabled(LogLevels[levelName])) {
    return;
  }
  const context = getContext();
  const logMsg: Record<string, any> = { ...context, ...params };
  logMsg.level = levelName;
  logMsg.message = message;

  console.log(JSON.stringify(logMsg));
};

export const debug = (msg: string, params?: Record<string, any>): void =>
  log("DEBUG", msg, params);
export const info = (msg: string, params?: Record<string, any>): void =>
  log("INFO", msg, params);
export const warn = (
  msg: string,
  params?: Record<string, any>,
  err?: Error
): void => log("WARN", msg, appendError(params, err));
export const error = (
  msg: string,
  params?: Record<string, any>,
  err?: Error
): void => log("ERROR", msg, appendError(params, err));
export interface SampleLoggingConfig {
  sampleRate: number;
}
