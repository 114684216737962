import { ThemeOptions, createMuiTheme } from '@material-ui/core/styles';
import buttonTheme, { buttonDefaultProps } from './buttonTheme';
import {
  buttonBaseDefaultProps,
  formHelperText,
  formLabelTheme,
  inputAdornmentTheme,
  inputBaseTheme,
  inputLabelDefaultProps,
  inputLabelTheme,
  inputTheme,
  textFieldDefaultProps,
} from './inputTheme';
import { formControlTheme } from './formControlTheme';
import { radioTheme } from './radioTheme';
import { paperTheme, listTheme, selectTheme, menuTheme, dividerTheme, listItemTheme } from './dropdownTheme';
import { checkboxTheme } from './checkboxTheme';
import { BreakpointsOptions } from '@material-ui/core/styles/createBreakpoints';
import { getTypographyOptions, typoOverrideTheme } from './typographyTheme';
import { basePalette } from './basePalette';
import switchTheme from './switchTheme';

const breakpointOptions: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1600,
    xl: 1920,
  },
};

const baseThemeOptions: ThemeOptions = {
  palette: basePalette,
  breakpoints: breakpointOptions,
};

const baseTheme = createMuiTheme(baseThemeOptions);

export const themeOptions: ThemeOptions = {
  palette: basePalette,
  breakpoints: breakpointOptions,
  props: {
    MuiButton: buttonDefaultProps,
    MuiTextField: textFieldDefaultProps,
    MuiInputLabel: inputLabelDefaultProps,
    MuiButtonBase: buttonBaseDefaultProps,
  },
  typography: getTypographyOptions(baseTheme),
  overrides: {
    MuiButton: buttonTheme(),
    MuiInputBase: inputBaseTheme,
    MuiInput: inputTheme,
    MuiFormHelperText: formHelperText,
    MuiInputLabel: inputLabelTheme,
    MuiFormLabel: formLabelTheme,
    MuiInputAdornment: inputAdornmentTheme,
    MuiFormControl: formControlTheme,
    MuiCheckbox: checkboxTheme,
    MuiSwitch: switchTheme(baseTheme),
    MuiFormControlLabel: {
      label: {
        marginBottom: 0,
        fontFamily: 'SourceSansPro',
        fontWeight: 600,
        lineHeight: '20px',
        height: 20,
        '& > .form-control-hint-text': {
          position: 'relative',
          top: 0,
          left: 0,
          fontFamily: 'SourceSansPro',
          fontWeight: 400,
        },
      },
    },
    MuiSelect: selectTheme,
    MuiPaper: paperTheme,
    MuiList: listTheme,
    MuiTypography: typoOverrideTheme,
    MuiMenu: menuTheme,
    MuiDivider: dividerTheme,
    MuiListItem: listItemTheme,
    MuiRadio: radioTheme,
  },
};
export const mainTheme = createMuiTheme(themeOptions);

export default mainTheme;
