import { SwitchClassKey } from '@material-ui/core/Switch';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import { monochromeDark, monochromeDarkest, monochromeMid, white, getDarkest } from '../../abstracts/colours';

export const switchTheme = (theme: Theme): Partial<Record<SwitchClassKey, CSSProperties>> => {
  const { palette } = theme;

  return {
    root: {
      width: 44,
      height: 24,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      '&:active': {
        '& + $track': {
          backgroundColor: monochromeDarkest,
          borderColor: monochromeDarkest,
        },
      },
      '&:hover': {
        '& + $track': {
          backgroundColor: monochromeDark,
          borderColor: monochromeDark,
        },
      },
      '&$checked': {
        transform: 'translateX(20px)',
        '& + $track': {
          backgroundColor: palette.primary.main,
          borderColor: palette.primary.main,
          opacity: 1,
        },
        '&:active': {
          '& + $track': {
            backgroundColor: getDarkest(palette.primary),
            borderColor: getDarkest(palette.primary),
          },
        },
        '&:hover': {
          '& + $track': {
            backgroundColor: palette.primary.dark,
            borderColor: palette.primary.dark,
          },
        },
        '&$disabled + $track': {
          opacity: 1,
          backgroundColor: palette.primary.main,
          borderColor: palette.primary.main,
        },
      },
      '&$disabled + $track': {
        opacity: 1,
        backgroundColor: monochromeMid,
        borderColor: monochromeMid,
      },
    },
    colorPrimary: {
      '&$disabled + $track': {
        opacity: 1,
        backgroundColor: monochromeMid,
        borderColor: monochromeMid,
      },
    },
    thumb: {
      color: white,
    },
    track: {
      border: `1px solid ${palette.text.secondary}`,
      borderRadius: 13,
      opacity: 1,
      backgroundColor: palette.text.secondary,
    },
  };
};

export default switchTheme;
