import { FormControlClassKey } from '@material-ui/core/FormControl';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { monochromeDark } from '../../abstracts/colours';

export const formControlTheme: Partial<Record<FormControlClassKey, CSSProperties>> = {
  root: {
    minWidth: 'auto',
    '& > .textfield-label': {
      marginBottom: 8,
      '& > div': {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '20px',
        color: monochromeDark,
        '&.disabled': {
          opacity: 0.3,
        },
      },
    },
    '&.phone-input': {
      '& .phone-input-textfield': {
        width: '100%',
        borderRadius: '0 4px 4px 0',
      },
      '& .phone-input-dropdown': {
        maxWidth: 100,
        '& .select-input': {
          width: '100%',
          borderRight: 'none',
          borderRadius: '4px 0 0 4px',
        },
      },
    },
  },
};

export default {
  formControlTheme,
};
