/* eslint-disable @typescript-eslint/no-explicit-any */
export const clearAll = (): void => {
  global.CONTEXT = undefined;
};

export const replaceAllWith = (ctx: Record<string, any>): void => {
  global.CONTEXT = ctx;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const set = (key: string, value: any): void => {
  let keyToUse = key;
  if (!keyToUse.startsWith("x-correlation-")) {
    keyToUse = `x-correlation-${keyToUse}`;
  }

  if (!global.CONTEXT) {
    global.CONTEXT = {};
  }

  global.CONTEXT[keyToUse] = value;
};

export const get = (): Record<string, any> => global.CONTEXT || {};

export interface CaptureCorrelationIdConfig {
  sampleDebugLogRate: number;
}
