import React from 'react';
import { makeStyles, Container, Box } from '@material-ui/core';
import colours from '../../../abstracts/colours';

const useStyles = makeStyles(() => ({
  productHeader: () => ({
    borderBottom: `1px solid ${colours.monochromeLight}`,
    padding: '1.125rem 0',
    background: 'white',
    '& .logo': {
      height: '32px',
      '& svg': {
        height: '100%',
        width: 'auto',
      },
    },
  }),
}));

export interface ProductHeaderProps {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}
const ProductHeader: React.FC<ProductHeaderProps> = ({ children, maxWidth = false }) => {
  const classes = useStyles();
  return (
    <Box className={classes.productHeader}>
      <Container className="product-header-container" maxWidth={maxWidth as ProductHeaderProps['maxWidth']}>
        <Box display="flex" flexWrap="nowrap" alignItems="center">
          {children}
        </Box>
      </Container>
    </Box>
  );
};

export default ProductHeader;
