import React, { FC, useCallback, useRef } from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

import CheckboxChecked from '../../../icons/CheckboxChecked';
import CheckboxIcon from '../../../icons/CheckboxIcon';
import { Color, InferPropTypes, NonNullFields } from '../../types';

export const checkboxPropTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hintText: PropTypes.string,
  required: PropTypes.bool,
};

export const defaultProps = {
  color: 'primary',
};

export type CheckboxProps = NonNullFields<InferPropTypes<typeof checkboxPropTypes, typeof defaultProps>>;

const Checkbox: FC<CheckboxProps> = ({
  label,
  checked,
  name,
  color,
  className,
  dataTestId,
  onChange,
  disabled,
  hintText,
  required,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e?.target.checked);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        inputRef?.current?.click();
      }
    },
    [inputRef],
  );

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          inputRef={inputRef}
          name={name || undefined}
          checked={checked}
          data-testid={`${dataTestId}-box`}
          color={color as Color}
          disableRipple
          onChange={handleChange}
          disabled={disabled || undefined}
          icon={<CheckboxIcon />}
          checkedIcon={<CheckboxChecked />}
          onKeyDown={handleKeyDown}
          required={required}
        />
      }
      label={
        <>
          {label}
          {required && '*'}
          {hintText && <div className="form-control-hint-text">{hintText}</div>}
        </>
      }
      className={className || undefined}
      data-testid={dataTestId}
      disabled={disabled || undefined}
    />
  );
};

Checkbox.propTypes = checkboxPropTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
