import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { constants } from '../../abstracts';
import { darken } from '@material-ui/core/styles';
import { Theme, TypographyClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export const getTypographyOptions = (theme: Theme): TypographyOptions => ({
  h1: {
    fontFamily: constants.lato,
    lineHeight: '44px',
    fontSize: '40px',
    // darken primay with 0.5 gives us monochromeDarkest = '#212121'
    // doing it this way means we can achieve the design system colours
    // using the mui variables available and have it still look good if
    // consumer changes their primary colour.
    color: darken(theme.palette.text.primary, 0.5),
  },
  h2: {
    fontFamily: constants.lato,
    color: darken(theme.palette.text.primary, 0.5),
    lineHeight: '36px',
    fontSize: '32px',
  },
  h3: {
    fontFamily: constants.lato,
    color: darken(theme.palette.text.primary, 0.13),
    lineHeight: '28px',
    fontSize: '24px',
  },
  h4: {
    fontFamily: constants.lato,
    color: darken(theme.palette.text.primary, 0.5),
    lineHeight: '24px',
    fontSize: '20px',
  },
  h5: {
    fontFamily: constants.lato,
    color: darken(theme.palette.text.primary, 0.5),
    lineHeight: '22px',
    fontSize: '18px',
  },
  body1: {
    fontFamily: constants.sourceSansPro,
    color: theme.palette.text.primary,
    lineHeight: '20px',
    fontSize: '16px',
  },
  body2: {
    fontFamily: constants.sourceSansPro,
    lineHeight: '18px',
    color: theme.palette.text.primary,
    fontSize: '14px',
  },
  button: {
    fontFamily: constants.sourceSansPro,
    lineHeight: '20px',
    fontSize: '16px',
    color: theme.palette.text.primary,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  subtitle1: {
    fontFamily: constants.sourceSansPro,
    lineHeight: '20px',
    color: darken(theme.palette.text.primary, 0.5),
    fontWeight: 700,
    fontSize: '16px',
  },
  subtitle2: {
    fontFamily: constants.sourceSansPro,
    lineHeight: '20px',
    fontWeight: 600,
    color: darken(theme.palette.text.primary, 0.5),
    fontSize: '16px',
  },
  caption: {
    fontFamily: constants.sourceSansPro,
    lineHeight: '15px',
    color: darken(theme.palette.text.primary, 0.5),
    fontSize: '12px',
  },
});

export const typoOverrideTheme: Partial<Record<TypographyClassKey, CSSProperties>> = {
  gutterBottom: {
    marginBottom: '0.5rem',
  },
  h1: {
    '&.MuiTypography-gutterBottom': {
      marginBottom: '2rem',
    },
  },
  h2: {
    '&.MuiTypography-gutterBottom': {
      marginBottom: '1.875rem',
    },
  },
  h3: {
    '&.MuiTypography-gutterBottom': {
      marginBottom: '1.5rem',
    },
  },
  h4: {
    '&.MuiTypography-gutterBottom': {
      marginBottom: '1.125rem',
    },
  },
  h5: {
    '&.MuiTypography-gutterBottom': {
      marginBottom: '1rem',
    },
  },
  caption: {
    '&.semi-bold': {
      fontWeight: 600,
    },
  },
};
