import { ButtonClassKey, ButtonTypeMap } from '@material-ui/core/Button';
import { OverrideProps } from '@material-ui/core/OverridableComponent';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { monochromeLightest, monochromeLight } from '../../abstracts/colours';

export const buttonTheme = (): Partial<Record<ButtonClassKey, CSSProperties>> => ({
  root: {
    borderRadius: '4px',
    fontSize: '16px',
    minWidth: '64px',
    boxShadow: 'none',
  },
  sizeLarge: {
    height: '44px',
    padding: '12px 16px',
    fontSize: '16px',
  },
  sizeSmall: {
    height: '36px',
    padding: '8px 16px',
    fontSize: '16px',
  },
  iconSizeSmall: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },
  iconSizeLarge: {
    '& > *:first-child': {
      fontSize: 20,
    },
  },
  contained: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '&:focus-visible': {
      boxShadow: 'none',
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },

  outlined: {
    backgroundColor: 'transparent',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:disabled': {
      opacity: 0.3,
    },
    '&:focus-visible': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  text: {
    '&:hover': {
      backgroundColor: monochromeLightest,
    },
    '&:active': {
      backgroundColor: monochromeLight,
    },
    '&:disabled': {
      opacity: 0.3,
    },
  },
});

export const buttonDefaultProps: Partial<OverrideProps<ButtonTypeMap<{}, 'button'>, 'button'>> = {
  variant: 'contained',
  color: 'primary',
  type: 'button',
  size: 'large',
  disableTouchRipple: true,
};

export default buttonTheme;
