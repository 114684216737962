import React, { useRef } from 'react';
import { makeStyles, Menu, Hidden, Avatar, IconButton, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { InferPropTypes, NonNullFields } from '../../types';
import FallbackAvatar from '../../../icons/FallbackAvatar';
import ConditionalWrap from '../../../utils/ConditionalWrap';

export const userPropTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string,
  imageSrc: PropTypes.string,
};

const useStyles = makeStyles(() => ({
  userMenu: () => ({
    '& .avatar-button': {
      padding: 0,
      '&:hover': {
        '& img': {
          filter: 'brightness(0.9)',
        },
        '& .fallback-avatar': {
          filter: 'brightness(0.9)',
        },
      },
    },
    '&.isOpen': {
      '& .avatar-button': {
        filter: 'brightness(0.8)',
      },
    },
  }),
}));

export type UserMenuProps = NonNullFields<InferPropTypes<typeof userPropTypes>>;

const UserMenu: React.FC<UserMenuProps> = ({ name, children, role, imageSrc, id, open, onClose, onClick }) => {
  const buttonEl = useRef<HTMLButtonElement | null>(null);
  const classes = useStyles();
  return (
    <Box data-userid={id} display="flex" alignItems="center" className={`${classes.userMenu} ${open ? 'isOpen' : ''}`}>
      <ConditionalWrap
        condition={React.Children.count(children) > 0}
        wrap={(kids): JSX.Element => (
          <IconButton
            ref={buttonEl}
            className={'avatar-button'}
            data-testid="avatar-button"
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={onClick}
          >
            {kids}
          </IconButton>
        )}
      >
        <Avatar alt={name} src={imageSrc}>
          <Box className="av-wrap">
            <FallbackAvatar />
          </Box>
        </Avatar>
      </ConditionalWrap>
      {React.Children.count(children) > 0 && (
        <Menu
          data-testid="user-menu"
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          id="user-menu"
          anchorEl={buttonEl.current}
          keepMounted
          open={open}
          onClose={onClose}
        >
          {children}
        </Menu>
      )}
      <Hidden smDown>
        <Box ml={1}>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="body1">{role}</Typography>
        </Box>
      </Hidden>
    </Box>
  );
};

UserMenu.propTypes = userPropTypes;

export default UserMenu;
