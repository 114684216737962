import React, { ChangeEvent, FC, useState, useMemo, useCallback } from 'react';
import { MenuItem, Box, FormControl, Divider, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { TextField, allCountries, Dropdown, Country } from '../../..';
import { InputLabel } from '../InputLabel';
import { ValidationType, ValidationPropType, NonNullFields, InferPropTypes } from '../../types';

function countryToFlag(isoCode: string): React.ReactElement {
  if (typeof String.fromCodePoint === 'undefined') {
    return <span>{isoCode}</span>;
  }
  return <>{isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))}</>;
}

const phoneInputPropTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  optional: PropTypes.bool,
  hintText: PropTypes.string,
  helperText: PropTypes.string,
  validationType: PropTypes.oneOf(ValidationPropType),
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  required: PropTypes.bool,
  countries: PropTypes.array,
  onDropdownChange: PropTypes.func,
};

export type PhoneInputProps = NonNullFields<InferPropTypes<typeof phoneInputPropTypes>>;

const PhoneInput: FC<PhoneInputProps> = ({
  label,
  id,
  optional,
  hintText,
  disabled,
  helperText,
  validationType,
  onChange,
  value,
  className,
  dataTestId,
  required,
  countries,
  onDropdownChange,
}) => {
  const renderFlag = (optionValue: unknown): React.ReactNode =>
    optionValue ? (
      <Box data-testid={dataTestId && `${dataTestId}-flag`} fontSize="30px">
        {countryToFlag(optionValue as string)}
      </Box>
    ) : (
      <Box border="1px solid #9e9e9e" width="30px"></Box>
    );

  const [selectedCountry, setSelectedCountry] = useState<{ code: string; enteredNumber?: string }>({
    code: '',
    enteredNumber: '',
  });

  const groupedCountries = useMemo(
    () => ({
      suggested: ((countries as Country[]) || allCountries).filter((c) => c.suggested),
      otherCountries: (countries as Country[]) || allCountries,
    }),
    [countries],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (onChange) {
        return onChange(e);
      }
      setSelectedCountry({
        ...selectedCountry,
        enteredNumber: (e.target.value || '').slice(selectedCountry.code.length),
      });
    },
    [onChange, selectedCountry],
  );

  const handleDropdownChange = useCallback(
    (e: ChangeEvent<{ value: unknown }>): void => {
      if (onDropdownChange) {
        return onDropdownChange(e);
      }
      const countryCode = allCountries.find((x) => x.abbr === e.target.value)?.code || '';
      setSelectedCountry({ code: countryCode, enteredNumber: '' });
    },
    [onDropdownChange],
  );

  return (
    <FormControl
      required={required}
      data-testid={dataTestId}
      className={clsx('phone-input', className)}
      fullWidth
      error={!!(validationType as ValidationType)}
    >
      {label && (
        <InputLabel
          required={required}
          htmlFor={id}
          label={label}
          optional={optional}
          hintText={hintText}
          disabled={disabled}
        />
      )}
      <Box display="inline-flex">
        <Dropdown
          dataTestId={dataTestId && `${dataTestId}-dropdown`}
          className={clsx('phone-input-dropdown', className && `${className}-dropdown`)}
          onChange={handleDropdownChange}
          renderValue={renderFlag}
          validationType={validationType as ValidationType}
          disabled={disabled}
        >
          {(groupedCountries.suggested || []).map((option) => (
            <MenuItem
              key={option.abbr}
              data-testid={dataTestId && `${dataTestId}-suggested-option-${option.abbr}`}
              value={option.abbr}
            >
              <Box marginRight="8px" fontSize="30px">
                {countryToFlag(option.abbr)}
              </Box>
              <span>
                {option.name} ({option.code})
              </span>
            </MenuItem>
          ))}
          {groupedCountries.suggested && groupedCountries.suggested.length && <Divider />}
          {(groupedCountries.otherCountries || []).map((option) => (
            <MenuItem
              key={option.abbr}
              value={option.abbr}
              data-testid={dataTestId && `${dataTestId}-other-option-${option.abbr}`}
            >
              <Box marginRight="8px" fontSize="30px">
                {countryToFlag(option.abbr)}
              </Box>
              <span>
                {option.name} ({option.code})
              </span>
            </MenuItem>
          ))}
        </Dropdown>
        <TextField
          required={required}
          dataTestId={dataTestId && `${dataTestId}-textfield`}
          className={clsx('phone-input-textfield', className && `${className}-textfield`)}
          disabled={disabled}
          value={value || selectedCountry.code + selectedCountry.enteredNumber}
          onChange={handleChange}
          validationType={validationType as ValidationType}
          type="text"
        />
      </Box>
      {helperText && validationType && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

PhoneInput.propTypes = phoneInputPropTypes;

export default PhoneInput;
