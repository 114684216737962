import React from 'react';
import PropTypes from 'prop-types';
import { Hidden, Box, Divider, Typography, makeStyles } from '@material-ui/core';
import { InferPropTypes, NonNullFields } from '../../types';

export const productTitleTextPropTypes = {
  productName: PropTypes.string.isRequired,
};

export type ProductTitleTextProps = NonNullFields<InferPropTypes<typeof productTitleTextPropTypes>>;

const useStyles = makeStyles(() => ({
  productHeaderDivider: () => ({
    marginBottom: '-1.125rem',
    marginTop: '-1.125rem',
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
  }),
}));

const ProductTitle: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Hidden smDown>
        <Divider className={classes.productHeaderDivider} orientation="vertical" flexItem />
      </Hidden>
      <Box>{children}</Box>
    </>
  );
};
const ProductTitleText: React.FC<ProductTitleTextProps> = ({ productName }) => (
  <ProductTitle>
    <Typography variant="h5">{productName}</Typography>
  </ProductTitle>
);

ProductTitleText.propTypes = productTitleTextPropTypes;

export default ProductTitleText;
