import PropTypes from 'prop-types';

export type ButtonType = 'button' | 'submit';
export type Variant = 'standard' | 'outlined' | 'filled';
export type Size = 'large' | 'small';
export type Color = 'primary' | 'secondary' | 'default';
export type ValidationType = 'error' | 'warning' | 'success';
export type InputType = 'text' | 'password' | 'email' | 'number';

export const VariantPropType = ['standard', 'contained', 'outlined', 'text'];
export const ValidationPropType = ['error', 'warning', 'success'];

export type InferPropTypes<PropTypes, DefaultProps = {}, Props = PropTypes.InferProps<PropTypes>> = {
  [Key in keyof Props]: Key extends keyof DefaultProps ? Props[Key] | DefaultProps[Key] : Props[Key];
};

export type NonNullFields<T> = { [key in keyof T]: NonNullable<T[key]> };
