import { createGlobalStyle } from 'styled-components';

import SourceSansProSvg from '../assets/fonts/SourceSansPro-Regular.svg';
import SourceSansProEot from '../assets/fonts/SourceSansPro-Regular.eot';
import SourceSansProTtf from '../assets/fonts/SourceSansPro-Regular.ttf';
import SourceSansProWoff from '../assets/fonts/SourceSansPro-Regular.woff';
import SourceSansProWoff2 from '../assets/fonts/SourceSansPro-Regular.woff2';

import SourceSansProSemiBoldSvg from '../assets/fonts/SourceSansPro-SemiBold.svg';
import SourceSansProSemiBoldEot from '../assets/fonts/SourceSansPro-SemiBold.eot';
import SourceSansProSemiBoldTtf from '../assets/fonts/SourceSansPro-SemiBold.ttf';
import SourceSansProSemiBoldWoff from '../assets/fonts/SourceSansPro-SemiBold.woff';
import SourceSansProSemiBoldWoff2 from '../assets/fonts/SourceSansPro-SemiBold.woff2';

import SourceSansProBoldSvg from '../assets/fonts/SourceSansPro-Bold.svg';
import SourceSansProBoldEot from '../assets/fonts/SourceSansPro-Bold.eot';
import SourceSansProBoldTtf from '../assets/fonts/SourceSansPro-Bold.ttf';
import SourceSansProBoldWoff from '../assets/fonts/SourceSansPro-Bold.woff';
import SourceSansProBoldWoff2 from '../assets/fonts/SourceSansPro-Bold.woff2';

import LatoBoldEot from '../assets/fonts/Lato-Bold.eot';
import LatoBoldTtf from '../assets/fonts/Lato-Bold.ttf';
import LatoBoldWoff from '../assets/fonts/Lato-Bold.woff';
import LatoBoldWoff2 from '../assets/fonts/Lato-Bold.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'SourceSansPro';
        src: local('SourceSansPro'),
        url(${SourceSansProTtf}) format('truetype'),
        url(${SourceSansProEot}),
        url(${SourceSansProSvg}),
        url(${SourceSansProWoff}) format('woff'),
        url(${SourceSansProWoff2}) format('woff2');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'SourceSansPro';
        src: local('Source Sans Pro Semibold'),
        local('SourceSansPro-Semibold'),
        url(${SourceSansProSemiBoldTtf}) format('truetype'),
        url(${SourceSansProSemiBoldEot}),
        url(${SourceSansProSemiBoldSvg}),
        url(${SourceSansProSemiBoldWoff}) format('woff'),
        url(${SourceSansProSemiBoldWoff2}) format('woff2');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'SourceSansPro';
        src: local('Source Sans Pro Bold'),
        local('SourceSansPro-Bold'),
        url(${SourceSansProBoldTtf}) format('truetype'),
        url(${SourceSansProBoldEot}),
        url(${SourceSansProBoldSvg}),
        url(${SourceSansProBoldWoff}) format('woff'),
        url(${SourceSansProBoldWoff2}) format('woff2');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Lato';
        src: local('Lato-Bold'),
        url(${LatoBoldTtf}) format('truetype'),
        url(${LatoBoldEot}),
        url(${LatoBoldWoff}) format('woff'),
        url(${LatoBoldWoff2}) format('woff2');
        font-weight: bold;
        font-style: normal;
    }
`;
