import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  monochromeMid,
  monochromeDarkest,
  white,
  negative,
  warning,
  positive,
  black,
  monochromeLightest,
  monochromeLight,
} from '../../abstracts/colours';

import { sourceSansPro } from '../../abstracts/constants';
import {
  InputBaseClassKey,
  InputClassKey,
  FormHelperTextClassKey,
  InputLabelProps,
  TextFieldProps,
  InputLabelClassKey,
  FormLabelClassKey,
  InputAdornmentClassKey,
  ButtonBaseProps,
} from '@material-ui/core';

const inputBaseTheme: Partial<Record<InputBaseClassKey, CSSProperties>> = {
  root: {
    height: '44px',
    marginBottom: '8px',
    fontFamily: sourceSansPro,
    marginTop: 0,
    border: `1px solid ${monochromeMid}`,
    borderRadius: '4px',
    backgroundColor: white,
    '&.select-input': {
      borderColor: monochromeMid,
      '&:hover': {
        backgroundColor: monochromeLightest,
        borderColor: monochromeMid,
      },
      '&$focused': {
        backgroundColor: monochromeLight,
        borderColor: monochromeMid,
      },
      '&.error': {
        borderColor: negative,
      },
      '&.warning': {
        borderColor: warning,
      },
    },
    '&$focused': {
      borderColor: monochromeDarkest,
    },
    '&:hover': {
      borderColor: monochromeDarkest,
    },
    '&$disabled': {
      opacity: 0.3,
      color: monochromeDarkest,
    },
    '&$disabled:hover': {
      opacity: 0.3,
      borderColor: monochromeMid,
      '&.select-input': {
        opacity: 0.3,
        borderColor: monochromeMid,
        backgroundColor: white,
      },
    },
    '&.helper-error': {
      borderColor: negative,
    },
    '&.helper-warning': {
      borderColor: warning,
    },
    '&.helper-success': {
      borderColor: positive,
    },
  },
  input: {
    padding: '10px 16px',
    borderRadius: 4,
  },
  multiline: {
    height: 'auto',
    padding: 0,
    '& > .MuiSvgIcon-root': {
      position: 'absolute',
      top: '12px',
      right: 0,
    },
  },
  inputMultiline: {
    minHeight: '20px',
    padding: '12px 6px 16px 16px',
    resize: 'vertical',
    '&$inputAdornedEnd': {
      paddingRight: '40px',
    },
  },
  adornedEnd: {
    '& > .MuiSvgIcon-root': {
      lineHeight: '20px',
      fontSize: '20px',
      marginRight: 18,
      '&.helper-error': {
        color: negative,
      },
      '&.helper-warning': {
        color: warning,
      },
      '&.helper-success': {
        color: positive,
      },
      '&.password-visibility': {
        cursor: 'pointer',
      },
    },
  },
};

const inputTheme: Partial<Record<InputClassKey, CSSProperties>> = {
  input: {
    backgroundColor: white,
    fontFamily: sourceSansPro,
    fontSize: '16px',
    lineHeight: '20px',
    color: monochromeDarkest,
  },
  formControl: {
    'label + &': {
      marginTop: 0,
    },
  },
  underline: {
    '&:before': {
      borderBottom: 'none',
    },
  },
};

const formHelperText: Partial<Record<FormHelperTextClassKey, CSSProperties>> = {
  root: {
    marginTop: 0,
    color: negative,
    '&.helper-warning': {
      color: warning,
    },
  },
};

const inputLabelTheme: Partial<Record<InputLabelClassKey, CSSProperties>> = {
  formControl: {
    position: 'static',
    transform: 'none',
    color: monochromeDarkest,
    fontFamily: sourceSansPro,
    fontWeight: 600,
    marginBottom: 0,
    lineHeight: '20px',
    '& > .optional-flag': {
      fontFamily: sourceSansPro,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '20px',
    },
  },
  asterisk: {
    color: negative,
  },
};

const formLabelTheme: Partial<Record<FormLabelClassKey, CSSProperties>> = {
  root: {
    '&$focused': {
      color: monochromeDarkest,
    },
    '&$disabled': {
      color: monochromeDarkest,
      opacity: 0.3,
    },
    '&$error': {
      color: monochromeDarkest,
    },
  },
};

const inputAdornmentTheme: Partial<Record<InputAdornmentClassKey, CSSProperties>> = {
  root: {
    '& > .MuiIconButton-root': {
      height: 24,
      width: 24,
      opacity: 1,
      color: black,
      marginRight: 18,
    },
  },
};

const textFieldDefaultProps: Partial<TextFieldProps> = {
  InputProps: {
    disableUnderline: true,
  },
};

const inputLabelDefaultProps: Partial<InputLabelProps> = {
  disableAnimation: true,
  shrink: false,
};

const buttonBaseDefaultProps: Partial<ButtonBaseProps> = {
  disableTouchRipple: true,
};

export {
  inputBaseTheme,
  inputTheme,
  textFieldDefaultProps,
  formHelperText,
  inputLabelDefaultProps,
  inputLabelTheme,
  formLabelTheme,
  inputAdornmentTheme,
  buttonBaseDefaultProps,
};
