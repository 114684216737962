import React, { FC, useState, useMemo } from 'react';
import { ErrorOutline, CheckCircleOutline, Visibility, VisibilityOff } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import { InputLabel } from '../InputLabel';
import { TextFieldProps, EndAdornmentProps, textfieldPropTypes, defaultPropTypes } from './types';
import { InputType, ValidationType, Variant } from '../../types';

const TextField: FC<TextFieldProps> = ({
  label,
  variant,
  helperText,
  validationType,
  type,
  disabled,
  onChange,
  value,
  required,
  id,
  inputProps,
  hintText,
  optional,
  errorWasSet,
  className,
  name,
  multiline,
  rowsMin,
  rowsMax,
  rows,
  style,
  dataTestId,
  endAdornment,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType: InputType = useMemo(() => {
    if (type !== 'password') {
      return type as InputType;
    }
    return showPassword ? 'text' : 'password';
  }, [showPassword, type]);

  const validationClassName: string = useMemo(() => {
    if (validationType) {
      return `helper-${validationType}`;
    }
    if (errorWasSet) {
      return 'helper-success';
    }
    return '';
  }, [validationType, errorWasSet]);

  return (
    <FormControl
      data-testid={dataTestId}
      fullWidth
      variant={variant as Variant}
      disabled={disabled}
      required={required}
    >
      {label && <InputLabel htmlFor={id} label={label} optional={optional} hintText={hintText} disabled={disabled} />}
      <Input
        fullWidth
        className={clsx(validationClassName, className)}
        type={inputType}
        inputProps={inputProps || { 'data-testid': dataTestId && `${dataTestId}-input` }}
        disableUnderline={true}
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        endAdornment={
          endAdornment || (
            <EndAdornment
              onClick={(): void => setShowPassword((show) => !show)}
              disabled={disabled}
              type={type as InputType}
              validationType={validationType as ValidationType}
              errorWasSet={errorWasSet}
              showPassword={showPassword}
              dataTestId={dataTestId}
            />
          )
        }
        multiline={multiline}
        rowsMin={rowsMin}
        rowsMax={rowsMax}
        rows={rows}
        style={style}
        data-testid={dataTestId && `${dataTestId}-input-div`}
        {...rest}
      />
      {validationType && ['error', 'warning'].includes(validationType) && helperText && (
        <FormHelperText className={`helper-${validationType}`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

TextField.propTypes = textfieldPropTypes;
TextField.defaultProps = defaultPropTypes;

const EndAdornment: FC<EndAdornmentProps> = ({
  disabled,
  type,
  validationType,
  errorWasSet,
  showPassword,
  onClick,
  dataTestId,
}) => {
  if (validationType) {
    return (
      <ErrorOutline data-testid={dataTestId && `${dataTestId}-adornment`} className={`helper-${validationType}`} />
    );
  }
  if (errorWasSet) {
    return <CheckCircleOutline data-testid={dataTestId && `${dataTestId}-adornment`} className="helper-success" />;
  }
  if (type === 'password') {
    return (
      <InputAdornment data-testid={dataTestId && `${dataTestId}-adornment`} position="end">
        <IconButton
          data-testid={dataTestId && `${dataTestId}-adornment-button`}
          disabled={disabled}
          disableTouchRipple
          onClick={onClick}
        >
          {!showPassword && <Visibility className="password-visibility" />}
          {showPassword && <VisibilityOff className="password-visibility" />}
        </IconButton>
      </InputAdornment>
    );
  }

  return null;
};

export default TextField;
