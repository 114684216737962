import React, { FC, useRef, ChangeEvent, KeyboardEvent } from 'react';
import { FormControlLabel, Radio as MuiRadio } from '@material-ui/core';
import PropTypes from 'prop-types';

import { NonNullFields, InferPropTypes, Color } from '../../types';
import { RadioIcon, RadioCheckedIcon } from '../../../icons';

export const radioPropTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hintText: PropTypes.string,
  required: PropTypes.bool,
};

export const defaultProps = {
  color: 'primary',
};

export type RadioProps = NonNullFields<InferPropTypes<typeof radioPropTypes, typeof defaultProps>>;

const Radio: FC<RadioProps> = ({
  label,
  checked,
  name,
  color,
  className,
  dataTestId,
  onChange,
  disabled,
  hintText,
  required,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <FormControlLabel
      control={
        <MuiRadio
          name={name || undefined}
          checked={checked}
          icon={<RadioIcon />}
          checkedIcon={<RadioCheckedIcon />}
          data-testid={`${dataTestId}-box`}
          color={color as Color}
          disableRipple
          onChange={onChange ? (e: ChangeEvent<HTMLInputElement>): void => onChange(e?.target.checked) : undefined}
          disabled={disabled || undefined}
          required={required}
          onKeyDown={(e: KeyboardEvent): void | undefined =>
            e.key === 'Enter' ? inputRef?.current?.click() : undefined
          }
          inputRef={inputRef}
        />
      }
      label={
        <>
          {label}
          {hintText && <div className="form-control-hint-text">{hintText}</div>}
        </>
      }
      className={className || undefined}
      data-testid={dataTestId}
      disabled={disabled || undefined}
    />
  );
};

Radio.propTypes = radioPropTypes;
Radio.defaultProps = defaultProps;

export default Radio;
