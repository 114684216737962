import React from 'react';

const RadioIcon: React.FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>SelectionControls/RadioButton/Deselected/Enabled</title>
    <g
      id="SelectionControls/RadioButton/Deselected/Enabled"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M12,0.5 C15.1756373,0.5 18.0506373,1.78718134 20.131728,3.86827202 C22.2128187,5.94936269 23.5,8.82436269 23.5,12 C23.5,15.1756373 22.2128187,18.0506373 20.131728,20.131728 C18.0506373,22.2128187 15.1756373,23.5 12,23.5 C8.82436269,23.5 5.94936269,22.2128187 3.86827202,20.131728 C1.78718134,18.0506373 0.5,15.1756373 0.5,12 C0.5,8.82436269 1.78718134,5.94936269 3.86827202,3.86827202 C5.94936269,1.78718134 8.82436269,0.5 12,0.5 Z"
        id="Rectangle"
        stroke="#9E9E9E"
        fill="#FFFFFF"
      ></path>
    </g>
  </svg>
);

export default RadioIcon;
