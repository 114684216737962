import React, { FC } from 'react';

const ChevronIcon: FC = () => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/Chevron/Right</title>
    <g id="Icon/Chevron/Right" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="12" height="12"></rect>
      <polyline
        id="Path-3"
        stroke="#212121"
        strokeLinecap="round"
        transform="translate(6.000000, 6.000000) rotate(-90.000000) translate(-6.000000, -6.000000) "
        points="2 4 6 8 10 4"
      ></polyline>
    </g>
  </svg>
);

export default ChevronIcon;
