import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { getDarkest, getSuperLightest } from '../../abstracts/colours';

export const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    containedPrimary: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&:active': {
        backgroundColor: getDarkest(theme.palette.primary),
      },
      '&:disabled': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    containedSecondary: {
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark,
      },
      '&:active': {
        backgroundColor: getDarkest(theme.palette.secondary),
      },
      '&:disabled': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
    },
    outlinedPrimary: {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: getSuperLightest(theme.palette.primary),
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
      '&:disabled': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&:active': {
        borderColor: getDarkest(theme.palette.primary),
        color: getDarkest(theme.palette.primary),
      },
    },
    outlinedSecondary: {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: getSuperLightest(theme.palette.secondary),
        color: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.dark,
      },
      '&:disabled': {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
      },
      '&:active': {
        borderColor: getDarkest(theme.palette.secondary),
        color: getDarkest(theme.palette.secondary),
      },
    },
    textPrimary: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:active': {
        color: getDarkest(theme.palette.primary),
      },
    },
    textSecondary: {
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.dark,
      },
      '&:active': {
        color: getDarkest(theme.palette.secondary),
      },
    },
    outlinedDestructive: {
      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: getSuperLightest(theme.palette.error),
        color: theme.palette.error.dark,
        borderColor: theme.palette.error.dark,
      },
      '&:disabled': {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
      },
      '&:active': {
        borderColor: getDarkest(theme.palette.error),
        color: getDarkest(theme.palette.error),
      },
    },
    containedDestructive: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark,
      },
      '&:active': {
        backgroundColor: getDarkest(theme.palette.error),
      },
      '&:disabled': {
        backgroundColor: theme.palette.error.main,
      },
    },
    textDestructive: {
      color: theme.palette.error.main,
      '&:hover': {
        color: theme.palette.error.dark,
      },
      '&:active': {
        color: getDarkest(theme.palette.error),
      },
    },
  }),
);
