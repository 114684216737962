import React from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadCrumbsProps,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import clsx from 'clsx';
import { oneOf } from 'prop-types';

import ChevronIcon from '../../icons/ChevronIcon';
import { InferPropTypes, NonNullFields } from '../types';

const breadCrumbPropTypes = {
  gutter: oneOf(['sm', 'md', 'lg', false]),
};

export type BreadcrumbsProps = NonNullFields<InferPropTypes<typeof breadCrumbPropTypes>> & MuiBreadCrumbsProps;

export const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    padded: (props: BreadcrumbsProps) => {
      const gutterMap = {
        sm: 1.5,
        md: 2.5,
        lg: 3.5,
      };
      type Gutter = 'sm' | 'md' | 'lg';
      return {
        '& ol': {
          color: theme.palette.primary.main,
        },
        marginBottom: `${(props.gutter && gutterMap[props.gutter as Gutter]) || 0}rem`,
      };
    },
  }),
);

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  children,
  gutter = false,
  separator = <ChevronIcon />,
  className,
  ...rest
}) => {
  const classes = useStyles({ gutter: gutter as BreadcrumbsProps['gutter'] });
  return (
    <MuiBreadcrumbs separator={separator} {...rest} className={clsx(classes.padded, className)}>
      {children}
    </MuiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = breadCrumbPropTypes;

export default Breadcrumbs;
