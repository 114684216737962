import React, { FC } from 'react';
import MuiInputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import { InferPropTypes, NonNullFields } from '../../types';

const inputLabelPropTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.node,
  optional: PropTypes.bool,
  hintText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export type InputLabelProps = NonNullFields<InferPropTypes<typeof inputLabelPropTypes>>;

const InputLabel: FC<InputLabelProps> = ({ htmlFor, label, optional, hintText, disabled, required }) => (
  <label className="textfield-label" htmlFor={htmlFor}>
    <MuiInputLabel disabled={disabled} required={required}>
      {label}
      {optional && <span className="optional-flag"> (optional)</span>}
    </MuiInputLabel>
    {hintText && <div className={disabled ? 'disabled' : ''}>{hintText}</div>}
  </label>
);

InputLabel.propTypes = inputLabelPropTypes;

export default InputLabel;
