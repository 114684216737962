import { CheckboxClassKey } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import {
  corporateBlueDark,
  corporateBlueDarkest,
  monochromeDarkest,
  monochromeLight,
  monochromeLightest,
  white,
} from '../../abstracts/colours';

export const iconActionStyle = {
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: 'transparent',
    '& span': {
      '& svg': {
        '& path': {
          fill: monochromeLightest,
          stroke: monochromeDarkest,
        },
      },
    },
  },
  '&:active': {
    '& span': {
      '& svg': {
        '& path': {
          fill: monochromeLight,
          stroke: monochromeDarkest,
        },
      },
    },
  },
  '&$disabled': {
    '& span': {
      '& svg': {
        '& > g': {
          opacity: 0.3,
        },
      },
    },
  },
  '&$checked': {
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: 'transparent',
      '& span': {
        '& svg': {
          '& path': {
            stroke: 'none',
            '&:first-child': {
              fill: corporateBlueDark,
            },
            '&:last-child:': {
              fill: white,
            },
          },
        },
      },
    },
    '&:active': {
      '& span': {
        '& svg': {
          '& path': {
            stroke: 'none',
            '&:first-child': {
              fill: corporateBlueDarkest,
            },
            '&:last-child:': {
              fill: white,
            },
          },
        },
      },
    },
    '&$disabled': {
      '& span': {
        '& svg': {
          '& path:first-child': {
            opacity: 0.3,
          },
        },
      },
    },
  },
};

export const checkboxTheme: Partial<Record<CheckboxClassKey, CSSProperties>> = {
  root: {
    '&.MuiIconButton-root': iconActionStyle,
  },
};

export default { checkboxTheme };
