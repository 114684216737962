import { InputProps } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InferPropTypes, VariantPropType, NonNullFields, ValidationPropType } from '../../types';
import { CommonFormControlProps } from '../commonTypes';

export const textfieldPropTypes = {
  variant: PropTypes.oneOf(VariantPropType),
  label: PropTypes.node,
  helperText: PropTypes.node,
  value: PropTypes.oneOf(['', 0]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  inputProps: PropTypes.object,
  hintText: PropTypes.string,
  optional: PropTypes.bool,
  errorWasSet: PropTypes.bool,
  validationType: PropTypes.oneOf(ValidationPropType),
  className: PropTypes.string,
  name: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  dataTestId: PropTypes.string,
};

export const defaultPropTypes = {
  variant: 'standard',
  type: 'text',
  disabled: false,
  rows: 3,
};

export type TextFieldProps = NonNullFields<InferPropTypes<typeof textfieldPropTypes, typeof defaultPropTypes>> &
  InputProps;

export interface EndAdornmentProps extends Pick<CommonFormControlProps, 'type' | 'validationType'> {
  errorWasSet?: boolean;
  showPassword?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  dataTestId?: string;
}
