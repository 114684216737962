import React from 'react';

const RadioCheckedIcon: React.FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="SelectionControls/RadioButton/Selected/Enabled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M12,0 C18.627417,-1.21743675e-15 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 8.11624501e-16,18.627417 0,12 C-8.11624501e-16,5.372583 5.372583,1.21743675e-15 12,0 Z"
        id="Rectangle"
        fill="#0074BD"
      ></path>
      <path
        d="M12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 Z"
        id="Rectangle"
        fill="#FFFFFF"
      ></path>
    </g>
  </svg>
);

export default RadioCheckedIcon;
