import * as React from 'react';

const CheckboxChecked: React.FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="SelectionControls/Checkbox/Selected/Enabled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M4,0 L20,0 C22.209139,-4.05812251e-16 24,1.790861 24,4 L24,20 C24,22.209139 22.209139,24 20,24 L4,24 C1.790861,24 2.705415e-16,22.209139 0,20 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z"
        id="Rectangle"
        fill="#0074BD"
      ></path>
      <path
        d="M9,16.17 L5.53,12.7 C5.14,12.31 4.51,12.31 4.12,12.7 C3.73,13.09 3.73,13.72 4.12,14.11 L8.3,18.29 C8.69,18.68 9.32,18.68 9.71,18.29 L20.29,7.71 C20.68,7.32 20.68,6.69 20.29,6.3 C19.9,5.91 19.27,5.91 18.88,6.3 L9,16.17 Z"
        id="Path"
        fill="#FFFFFF"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export default CheckboxChecked;
