import React, { FC } from 'react';

const DragHandle: FC<{}> = () => (
  <svg
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group">
        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="24" height="24" rx="4"></rect>
        <g id="Group-5">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
          <line x1="16" y1="20" x2="20" y2="16" id="Path-9" stroke="#9E9E9E" strokeLinecap="round"></line>
          <line x1="10" y1="20" x2="20" y2="10" id="Path-9" stroke="#9E9E9E" strokeLinecap="round"></line>
          <line x1="4" y1="20" x2="20" y2="4" id="Path-9" stroke="#9E9E9E" strokeLinecap="round"></line>
        </g>
      </g>
    </g>
  </svg>
);

export default DragHandle;
